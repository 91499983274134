<template>
  <div>
    <b-dropdown
      no-caret
      dropright
      variant="slim"
      menu-class="relative z-10 text-14"
      toggle-class="text-gray-400 hover:text-gray-700 hover:drop-shadow-xl"
    >
      <template #button-content>
        <!-- <span class="my-6 icon_v2-so_menu-circle-vertical text-20" /> -->
        <span class="my-6 icon_v2-so_setting text-20" />
      </template>

      <!-- settings -->
      <b-dropdown-item @click.stop="edit">
        <span class="mr-8 icon_v2-so_setting" />
        Settings
      </b-dropdown-item>

      <!-- preview portal -->
      <b-dropdown-item
        v-b-tooltip.hover.right.v-info.dh0.ds200="`View as if you were a client`"
        @click.stop="openPortalPreview"
      >
        <span class="mr-8 icon_v2-so_eye" />
        Preview Portal
      </b-dropdown-item>

      <b-dropdown-divider />

      <!-- clone -->
      <FeatureLimit
        v-slot="{ checkFeatureLimit }"
        flag="limitProjects"
        label="projects"
        @allow="clone"
      >
        <b-dropdown-item
          :disabled="isCloning"
          @click.stop="checkFeatureLimit"
        >
          <span class="mr-8 icon_v2-so_copy-done" />
          Duplicate
        </b-dropdown-item>
      </FeatureLimit>

      <!-- archive / unarchive -->
      <b-dropdown-item v-if="project.archived" @click.stop="unarchive">
        <span class="mr-8 icon_v2-so_archive-remove" />
        Unarchive
      </b-dropdown-item>
      <b-dropdown-item v-else @click.stop="archive">
        <span class="mr-8 icon_v2-so_archive-done" />
        Archive
      </b-dropdown-item>

      <!-- delete -->
      <b-dropdown-item @click.stop="remove">
        <span class="mr-8 icon_v2-so_trash" />
        Delete
      </b-dropdown-item>
    </b-dropdown>
    <ModalBasic
      v-if="isPreviewModalOpen"
      v-model="isPreviewModalOpen"
      :no-close="false"
      :hide-footer="true"
      title="Portal Preview"
      size="lg"
    >
      <template #body="{ close }">
        <ProjectViewAsClient
          :project="project"
          @opened="close"
        />
      </template>
    </ModalBasic>
  </div>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import { useArchiveProject, useCloneProject, useRemoveProject } from '@/v2/services/projects/compositions'
import { useTracking } from '@/v2/services/tracking/compositions'
import { useRouter } from '@/v2/lib/composition/useRouter'
import { PROJECT_SETTINGS } from '@/router/project/type'
import FeatureLimit from '@/components/FeatureLimit.vue'
import ProjectViewAsClient from '@/components/ProjectViewAsClient.vue'
import ModalBasic from '@/components/Modal/ModalBasic.vue'

export default defineComponent({
  name: 'ProjectMenu',
  components: {
    FeatureLimit,
    ProjectViewAsClient,
    ModalBasic,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { track } = useTracking()
    const { routerPush } = useRouter()

    //
    // -- remove
    //
    const { remove: _remove } = useRemoveProject()
    const remove = () => _remove(props.project)

    //
    // -- clone
    //
    const { clone: _clone, isPending: isCloning } = useCloneProject()
    const clone = () => {
      track(['Project Duplicated'])
      return _clone(props.project)
    }

    //
    // -- (un)archive
    //
    const { archive, unarchive } = useArchiveProject()

    //
    // -- edit
    //
    const edit = () => {
      routerPush({
        name: PROJECT_SETTINGS,
        params: {
          organizationId: props.project.organization,
          projectId: props.project._id,
        },
      })
    }

    //
    // -- portal preview
    //
    const isPreviewModalOpen = ref(false)

    const openPortalPreview = () => {
      track('Project Viewed As Client', { source: 'Project Card Menu' })
      isPreviewModalOpen.value = true
    }

    return {
      isPreviewModalOpen,
      openPortalPreview,

      clone,
      isCloning,

      remove,
      edit,
      archive,
      unarchive,
    }
  },
})
</script>
