
<div data-intercom-target="Projects">
  <div
    v-if="hasHeaderSlot"
    class="flex items-center mb-10 cursor-pointer"
    @click="() => $emit('update:header-collapsed', !headerCollapsed)"
  >
    <span
      class="
        text-gray-400 inline-block transition duration-200 text-24
        ease-in-out transform icon_v2-so_direction-down
      "
      :class="{ '-rotate-180': headerCollapsed }"
    />
    <slot name="header" v-bind="{ projects }" />
  </div>
  <div v-show="!headerCollapsed">
    <section
      v-if="viewContext.groupBy === 'none'"
      class="organization-recent-projects"
      :class="`view-as-${viewContext.viewAs}`"
    >
      <!-- NEW PROJECT -->
      <ButtonNewProject
        v-if="(admin || projectManager) && conf.createButton && viewContext.viewAs !== 'list'"
        :projects="projects"
        view-as="card"
      />

      <p
        v-if="member && !projects.length"
        class="text-gray-500"
      >
        {{ $t('project-updates.nothing-to-show') }}
      </p>
      <template v-if="projects.length">
        <component
          :is="resolvedComponent"
          v-for="project in projects"
          :key="project.id"
          :project="project"
          :read-only="readOnly"
          :config="conf"
          :hide-amounts="hideAmounts"
          @select="open(project._id)"
        >
          <template v-if="config.menu && !readOnly" #menu="{ dark }">
            <ProjectMenu
              :dark="dark"
              :project="project"
            />
          </template>
          <slot name="footer" v-bind=" { project }" />
        </component>
      </template>
    </section>
    <div v-else class="flex flex-col gap-24">
      <div
        v-for="c in clients"
        :key="c.client._id"
      >
        <div
          class="
          flex items-center justify-between w-full border-b border-gray-200
          pb-8 mb-16 text-gray-500
        "
        >
          <h3 class="text-16 font-bold leading-none p-0 m-0">
            {{ c.client.name }}
          </h3>
          <div class="h-24 max-w-88 lg:max-w-none lg:h-32 flex items-center">
            <Logo
              :dark="c.client.logoDark"
              :light="c.client.logoLight"
              :fallback-name="c.client.name"
              :color="c.client.mainColor"
            />
          </div>
        </div>
        <div
          class="organization-recent-projects"
          :class="`view-as-${viewContext.viewAs}`"
        >
          <component
            :is="resolvedComponent"
            v-for="project in c.projects"
            :key="project.id"
            :project="project"
            :read-only="readOnly"
            :hide-amounts="hideAmounts"
            @select="open(project._id)"
          >
            <template v-if="config.menu && !readOnly" #menu="{ dark }">
              <ProjectListItemMenu
                :dark="dark"
                :project="project"
              />
            </template>
          </component>
        </div>
      </div>
    </div>
  </div>
</div>
