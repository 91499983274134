
<div>
  <b-dropdown
    no-caret
    dropright
    variant="slim"
    menu-class="relative z-10 text-14"
    toggle-class="text-gray-400 hover:text-gray-700 hover:drop-shadow-xl"
  >
    <template #button-content>
      <!-- <span class="my-6 icon_v2-so_menu-circle-vertical text-20" /> -->
      <span class="my-6 icon_v2-so_setting text-20" />
    </template>

    <!-- settings -->
    <b-dropdown-item @click.stop="edit">
      <span class="mr-8 icon_v2-so_setting" />
      Settings
    </b-dropdown-item>

    <!-- preview portal -->
    <b-dropdown-item
      v-b-tooltip.hover.right.v-info.dh0.ds200="`View as if you were a client`"
      @click.stop="openPortalPreview"
    >
      <span class="mr-8 icon_v2-so_eye" />
      Preview Portal
    </b-dropdown-item>

    <b-dropdown-divider />

    <!-- clone -->
    <FeatureLimit
      v-slot="{ checkFeatureLimit }"
      flag="limitProjects"
      label="projects"
      @allow="clone"
    >
      <b-dropdown-item
        :disabled="isCloning"
        @click.stop="checkFeatureLimit"
      >
        <span class="mr-8 icon_v2-so_copy-done" />
        Duplicate
      </b-dropdown-item>
    </FeatureLimit>

    <!-- archive / unarchive -->
    <b-dropdown-item v-if="project.archived" @click.stop="unarchive">
      <span class="mr-8 icon_v2-so_archive-remove" />
      Unarchive
    </b-dropdown-item>
    <b-dropdown-item v-else @click.stop="archive">
      <span class="mr-8 icon_v2-so_archive-done" />
      Archive
    </b-dropdown-item>

    <!-- delete -->
    <b-dropdown-item @click.stop="remove">
      <span class="mr-8 icon_v2-so_trash" />
      Delete
    </b-dropdown-item>
  </b-dropdown>
  <ModalBasic
    v-if="isPreviewModalOpen"
    v-model="isPreviewModalOpen"
    :no-close="false"
    :hide-footer="true"
    title="Portal Preview"
    size="lg"
  >
    <template #body="{ close }">
      <ProjectViewAsClient
        :project="project"
        @opened="close"
      />
    </template>
  </ModalBasic>
</div>
