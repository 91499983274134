<template>
  <div data-intercom-target="Projects">
    <div
      v-if="hasHeaderSlot"
      class="flex items-center mb-10 cursor-pointer"
      @click="() => $emit('update:header-collapsed', !headerCollapsed)"
    >
      <span
        class="
          text-gray-400 inline-block transition duration-200 text-24
          ease-in-out transform icon_v2-so_direction-down
        "
        :class="{ '-rotate-180': headerCollapsed }"
      />
      <slot name="header" v-bind="{ projects }" />
    </div>
    <div v-show="!headerCollapsed">
      <section
        v-if="viewContext.groupBy === 'none'"
        class="organization-recent-projects"
        :class="`view-as-${viewContext.viewAs}`"
      >
        <!-- NEW PROJECT -->
        <ButtonNewProject
          v-if="(admin || projectManager) && conf.createButton && viewContext.viewAs !== 'list'"
          :projects="projects"
          view-as="card"
        />

        <p
          v-if="member && !projects.length"
          class="text-gray-500"
        >
          {{ $t('project-updates.nothing-to-show') }}
        </p>
        <template v-if="projects.length">
          <component
            :is="resolvedComponent"
            v-for="project in projects"
            :key="project.id"
            :project="project"
            :read-only="readOnly"
            :config="conf"
            :hide-amounts="hideAmounts"
            @select="open(project._id)"
          >
            <template v-if="config.menu && !readOnly" #menu="{ dark }">
              <ProjectMenu
                :dark="dark"
                :project="project"
              />
            </template>
            <slot name="footer" v-bind=" { project }" />
          </component>
        </template>
      </section>
      <div v-else class="flex flex-col gap-24">
        <div
          v-for="c in clients"
          :key="c.client._id"
        >
          <div
            class="
            flex items-center justify-between w-full border-b border-gray-200
            pb-8 mb-16 text-gray-500
          "
          >
            <h3 class="text-16 font-bold leading-none p-0 m-0">
              {{ c.client.name }}
            </h3>
            <div class="h-24 max-w-88 lg:max-w-none lg:h-32 flex items-center">
              <Logo
                :dark="c.client.logoDark"
                :light="c.client.logoLight"
                :fallback-name="c.client.name"
                :color="c.client.mainColor"
              />
            </div>
          </div>
          <div
            class="organization-recent-projects"
            :class="`view-as-${viewContext.viewAs}`"
          >
            <component
              :is="resolvedComponent"
              v-for="project in c.projects"
              :key="project.id"
              :project="project"
              :read-only="readOnly"
              :hide-amounts="hideAmounts"
              @select="open(project._id)"
            >
              <template v-if="config.menu && !readOnly" #menu="{ dark }">
                <ProjectListItemMenu
                  :dark="dark"
                  :project="project"
                />
              </template>
            </component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { computed, defineComponent, ref } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'
import { groupBy } from 'ramda'
import { PROJECT_SETTINGS_DETAILS } from '@/router/project/type'
import { useRemoveProject, useSearchProjects } from '@/v2/services/projects/compositions'
// import ProjectGridItem from '@/components/ProjectGridItem.vue'
import ProjectSmallGridItem from '@/components/ProjectSmallGridItem.vue'
import ProjectListItem from '@/components/ProjectListItem.vue'
import ProjectMenu from '@/components/ProjectMenu.vue'
import ButtonNewProject from '@/components/ButtonNewProject.vue'
import Logo from '@/components/Logo.vue'
import ProjectViewAsClient from '@/components/ProjectViewAsClient.vue'

const viewAsToComponent = {
  // largeGrid: ProjectGridItem,
  smallGrid: ProjectSmallGridItem,
  list: ProjectListItem,
}

const makeDefaultConfig = () => ({
  createButton: true,
  menu: true,
  clickToOpenProject: true,
})

export default defineComponent({
  name: 'ProjectList',
  components: {
    // ProjectGridItem,
    ProjectMenu,
    ButtonNewProject,
    Logo,
    ProjectViewAsClient,
  },
  props: {
    config: {
      type: Object,
      default: makeDefaultConfig,
    },
    limit: {
      type: Number,
      default: 1000,
    },
    organizationId: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    viewContext: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
      default: () => ({}),
    },
    menuItems: {
      type: Object,
      default: () => ({}),
    },
    keyword: {
      type: String,
      default: '',
    },
    selectedTags: {
      type: Object,
      default: () => ({}),
    },
    headerCollapsed: {
      type: Boolean,
      default: false,
    },
    hideAmounts: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const isCollapsed = ref(false)
    const { Client } = Vue.$FeathersVuex.api
    const { remove } = useRemoveProject()

    const conf = computed(() => ({
      ...makeDefaultConfig(),
      ...props.config,
    }));

    const dateSort = {
      updatedAt: -1,
    }

    const nameSort = {
      name: 1,
    }

    const projectsQuery = computed(() => ({
      $limit: props.limit,
      $sort: props.viewContext.sortBy === 'alphabetical'
        ? { ...nameSort }
        : { ...dateSort },
      organization: props.organizationId,
      ...props.query,
    }))

    const { projects } = useSearchProjects({
      query: projectsQuery,
      selectedTags: computed(() => props.selectedTags),
      keyword: computed(() => props.keyword),
    });

    // -- find clients
    const { items: _clients } = useFind({
      model: Client,
      params: computed(() => ({
        query: {
          organization: props.organizationId,
        },
      })),
    })

    const clients = computed(() => {
      const projectsByClient = groupBy(p => p.client, projects.value)

      return _clients.value
        .map(client => ({
          client,
          projects: projectsByClient[client._id] ?? [],
        }))
        .filter(c => c.projects.length)
    })

    const resolvedComponent = computed(
      () => viewAsToComponent[props.viewContext.viewAs]
    )

    const open = id => {
      if (!conf.value.clickToOpenProject) {
        return
      }

      context.emit('open-project', id)
    }

    const hasHeaderSlot = computed(() => Boolean(context.slots.header))

    return {
      PROJECT_SETTINGS_DETAILS,
      open,
      // edit,
      remove,
      projects,
      resolvedComponent,
      conf,
      clients,
      isCollapsed,
      hasHeaderSlot,
    }
  },
})
</script>


<style scoped>


.view-as-largeGrid {
  @apply grid grid-flow-row-dense grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4;
  @apply gap-16 auto-cols-fr;
}

.view-as-smallGrid {
  @apply grid grid-flow-row-dense grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5;
  @apply gap-16 auto-cols-fr;
}

.view-as-list {
  @apply flex flex-col items-stretch;

}
</style>
