<template>
  <div class="border-b border-dotted pb-4">
    <ButtonTransparent
      class="flex items-center gap-8 text-gray-400 text-14 font-semibold"
      @click="$emit('back')"
    >
      <span class="icon_v2-so_direction-left" />
      <slot />
    </ButtonTransparent>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import ButtonTransparent from '@/components/ButtonTransparent.vue'

export default defineComponent({
  name: 'SidebarBack',
  components: {
    ButtonTransparent,
  },
})
</script>
